import React, { useState } from "react";
import "./OneTeamMember.css";
import ArrowRight from "./Arrows/ArrowRight";
import ArrowLeft from "./Arrows/ArrowLeft";
import CeoIcon from "../assets/images/CeoIcon.webp";
import OfficeManagerIcon from "../assets/images/OfficeManagerIcon.webp";
import MobileDeveloperIcon from "../assets/images/MobileDeveloperIcon.webp";
import FrontendDeveloperIcon from "../assets/images/FrontendDeveloperIcon.webp";
import BackendDeveloperIcon from "../assets/images/BackendDeveloperIcon.webp";
import UxUiDesignerIcon from "../assets/images/UxUiDesignerIcon.webp";
import MotionDesignerIcon from "../assets/images/MotionDesignerIcon.webp";
import { strings } from "../Translations/strings";
import LinkedinIcon from "../assets/icons/TeamLinkedin.svg";
import MediumIcon from "../assets/icons/TeamMedium.svg";
import DribbbleIcon from "../assets/icons/TeamDribbble.svg";
import BehanceIcon from "../assets/icons/TeamBehance.svg";
import WebIcon from "../assets/icons/TeamWeb.svg";

const OneTeamMember = ({ item }) => {
  const [showingFront, setShowingFront] = useState(true);

  const onPressIcon = () => {
    setShowingFront(!showingFront);
  };

  const icons = {
    [strings.adi_behlulovic_position]: CeoIcon,
    [strings.samra_behlulovic_position]: OfficeManagerIcon,
    [strings.sanjin_sehic_position]: MobileDeveloperIcon,
    [strings.neira_durmic_position]: FrontendDeveloperIcon,
    [strings.vehid_trtak_position]: FrontendDeveloperIcon,
    [strings.albin_didic_position]: OfficeManagerIcon,
    [strings.ajdin_djakusic_position]: BackendDeveloperIcon,
    [strings.amina_mehic_position]: UxUiDesignerIcon,
  };

  return (
    <div className={`team-member ${showingFront ? "front" : "back"}`}>
      {showingFront ? (
        <div className="team-front-content">
          <div className="team-front-image" style={{ height: "80%" }}>
            <img
              src={item?.image}
              alt={item?.name}
              draggable="false"
              loading="lazy"
            />
          </div>
          <div className="team-front-details" style={{ height: "20%" }}>
            <div className="team-icon-container">
              <img
                src={icons[item?.position]}
                alt="Icon"
                className="team-icon"
                draggable="false"
                loading="lazy"
              />
            </div>
            <div className="team-info">
              <p className="team-name">{item?.name}</p>
              <p className="team-position">{item?.position}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="team-back-content">
          <p className="team-infoTitle">{item?.infoTitle}</p>
          <p className="team-infoText">{item?.infoText}</p>
          <p className="team-connectWith">{item?.connectWith}</p>
          <div className="team-social-icons">
            {item?.linkedinLink && (
              <a
                href={item?.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LinkedinIcon}
                  alt="LinkedIn"
                  draggable="false"
                  loading="lazy"
                />
              </a>
            )}
            {item?.mediumLink && (
              <a
                href={item?.mediumLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={MediumIcon}
                  alt="Medium"
                  draggable="false"
                  loading="lazy"
                />
              </a>
            )}
            {item?.dribbbleLink && (
              <a
                href={item?.dribbbleLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={DribbbleIcon}
                  alt="Dribbble"
                  draggable="false"
                  loading="lazy"
                />
              </a>
            )}
            {item?.behanceLink && (
              <a
                href={item?.behanceLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={BehanceIcon}
                  alt="Behance"
                  draggable="false"
                  loading="lazy"
                />
              </a>
            )}
            {item?.webLink && (
              <a href={item?.webLink} target="_blank" rel="noopener noreferrer">
                <img src={WebIcon} alt="Web" draggable="false" loading="lazy" />
              </a>
            )}
          </div>
        </div>
      )}
      <div className="one-member-arrow-icon" onClick={onPressIcon}>
        {showingFront ? (
          <ArrowRight style={{ width: 48, height: 48 }} isAlwaysHovered />
        ) : (
          <ArrowLeft style={{ width: 48, height: 48 }} />
        )}
      </div>
    </div>
  );
};

export default OneTeamMember;
