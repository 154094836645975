import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Mills from "./mills";
import "../Ambassador/Ambassador.css";
import "./JoinNewsletter.css";

const JoinNewsletter = () => {
  const sectionRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#newsletter" && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div
      ref={sectionRef}
      id="target-section"
      className="joinnewsletter-content"
    >
      <div className="joinnewsletter-container">
        <h2
          data-aos="animation-scale-y"
          data-aos-delay="400"
          className="joinnewsletter-first-text"
        >
          From Start To Scale
        </h2>
        <div
          className="joinnewsletter-second-text"
          data-aos="animation-scale-y"
          data-aos-delay="400"
        >
          Join our Newsletter
        </div>
      </div>
      <div data-aos="animation-scale-y" data-aos-delay="400">
        <Mills
          formId={"7171689"}
          showHeadingText={false}
          className="convertkit-form"
        />
      </div>
    </div>
  );
};

export default JoinNewsletter;
