import React from "react";
import "./JoinNewsletter.css";

const DefaultContent = () => (
  <p>Subscribe to get our latest content by email.</p>
);

function Mills({
  hideName = true,
  showLabels = false,
  newTab = false,
  hideWarnings = false,
  stacked = false,
  className = "",
  showHeadingText = true,
  submitText = "Subscribe",
  disclaimerText = "We won't send you spam. Unsubscribe at any time.",
  emailPlaceholder = "Email Address",
  namePlaceholder = "First Name",
  nameLabel = "First name",
  emailLabel = "Email",
  format = "inline",
  headingText = "Join the Newsletter",
  children = <DefaultContent />,
  buttonColor = "rgb(255 255 255)",
  buttonBackground = "rgb(22 119 190)",
}) {
  const action = `https://app.kit.com/forms/7171689/subscriptions`;
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://f.convertkit.com/ckjs/ck.5.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <form
        action={action}
        className={`seva-form formkit-form mills ${className}`}
        method="post"
        target={newTab ? "_blank" : "_self"}
        data-sv-form={"7171689"}
        data-uid={"becf074893"}
        data-format={"inline"}
        data-version={5}
        data-options='\{"settings":\{"after_subscribe":\{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""\},"analytics":\{"google":null,"fathom":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null\},"modal":\{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15\},"powered_by":\{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"\},"recaptcha":\{"enabled":false\},"return_visitor":\{"action":"show","custom_content":""\},"slide_in":\{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15\},"sticky_bar":\{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15\}\},"version":"5"\}'
        min-width="400 500 600 700 800"
        style={{ backgroundColor: "rgb(249 250 251)", borderRadius: "4px" }}
      >
        <div className="formkit-background" style={{ opacity: 0.2 }}></div>
        <div data-style="minimal">
          {showHeadingText ? (
            <div
              className="formkit-header"
              data-element="header"
              style={{
                color: "rgb(77, 77, 77)",
                fontSize: "27px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              <h1>{headingText}</h1>
            </div>
          ) : null}
          <div
            className="formkit-subheader"
            data-element="subheader"
            style={{ color: "rgb(104 104 104)", fontsize: "18px" }}
          >
            {children}
          </div>
          <ul
            className="formkit-alert formkit-alert-error"
            data-element="errors"
            data-group="alert"
          ></ul>
          <div
            data-element="fields"
            data-stacked={stacked}
            className="seva-fields formkit-fields"
          >
            {!hideName && (
              <>
                {showLabels ? (
                  <label htmlFor="ck-first-name">{nameLabel}</label>
                ) : null}
                <div className="formkit-field">
                  <input
                    className="formkit-input"
                    aria-label={nameLabel}
                    name="fields[first_name]"
                    placeholder={namePlaceholder}
                    type="text"
                    style={{
                      color: "rgb(0 0 0)",
                      borderColor: "rgb(227 227 227)",
                      borderRadius: "4px",
                      fontWeight: 400,
                    }}
                    id="ck-first-name"
                  />
                </div>
              </>
            )}
            {showLabels ? <label htmlFor="ck-email">{emailLabel}</label> : null}
            <div className="formkit-field">
              <input
                className="formkit-input"
                aria-label={emailLabel}
                name="email_address"
                placeholder={emailPlaceholder}
                required=""
                type="email"
                style={{
                  color: "rgb(0 0 0)",
                  borderColor: "rgb(227 227 227)",
                  borderRadius: "4px",
                  fontWeight: 400,
                }}
                id="ck-email"
              />
            </div>
            <button
              data-element="submit"
              className="formkit-submit formkit-submit"
              style={{
                color: buttonColor,
                backgroundColor: buttonBackground,
                borderRadius: "4px",
                fontWeight: 400,
              }}
            >
              <div className="formkit-spinner">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span>{submitText}</span>
            </button>
          </div>
          <div
            className="formkit-guarantee"
            data-element="guarantee"
            style={{
              color: "rgb(77 77 77)",
              fontSize: "13px",
              fontWeight: 400,
            }}
          >
            {disclaimerText}
          </div>
        </div>
      </form>
    </>
  );
}

export default Mills;
