export const strings = {
  email_info: "info@scaleup.ba",
  address_info_one: "Radenka Abazovica 2A",
  address_info_two: "71000, Sarajevo",
  phone_info: "+387 61 654 780",
  medium_article_one:
    "https://medium.com/scaleuptech/input-form-validations-with-yup-in-react-native-14164c633b05",
  medium_article_two:
    "https://medium.com/scaleuptech/startup-series-1-introduction-6f79d788560c",
  facebook_link: "https://www.facebook.com/ScaleUPSarajevo/",
  linkedin_link: "https://www.linkedin.com/company/scaleup-ba/mycompany/",
  instagram_link: "https://www.instagram.com/scaleup.ba/?hl=en",
  medium_link: "https://medium.com/scaleuptech",
  behance_link: "https://www.behance.net/ScaleUp",
  dribbble_link: "https://dribbble.com/scaleuptech",
  showroom_link: "https://showroom.scaleup.ba",
  behance_session_house:
    "https://www.behance.net/gallery/177381945/Session-House-Mobile-App-UX-Case-Study",
  behance_tefter:
    "https://www.behance.net/gallery/186623111/Tefter-Mobile-App-Branding",
  manifesto_link:
    "https://docs.google.com/document/d/1AKm1WQQBziCMO45BoeU4DRkC799a-hwsgXwG8llf9NU/edit#heading=h.lfnnqlbxlyqh",
  head_start_link:
    "https://drive.google.com/file/d/1QI2WRJLDrkmZrtoum4YSHHZ0_hVUoykl/view?usp=share_link",
  product_launchpad_link:
    "https://drive.google.com/file/d/1cf6pdfuWJdaIRpODaqYp5IUjMQgP4rQe/view?usp=share_link",
  team_boost_link:
    "https://drive.google.com/file/d/1LR9ZAyxFoNzhuWqoZWukVsmFM5Ni1Gs8/view?usp=share_link",

  hero_title: "Stop searching, you've found us!",
  hero_text: "Scroll down",
  menu_title_one: "Showroom",
  menu_title_two: "Manifesto",
  menu_title_three: "About us",
  menu_title_four: "Offerings",
  menu_title_five: "Team",
  menu_title_six: "Blog",
  menu_title_seven: "Careers",
  dribbble_text: "Dribbble",
  beehance_text: "Beehance",
  linkedin_text: "Linkedin",
  medium_text: "Medium",
  instagram_text: "Instagram",
  who_we_are_title: "Who We Are",
  our_text: "Our",
  who_we_are_subtitle: "Our Manifesto",
  who_we_are_option_one: "Manifesto",
  who_we_are_option_two: "Mission",
  who_we_are_option_three: "Vision",
  who_we_are_option_four: "Core Values",
  manifesto_text:
    "We are ScaleUp, crafting scalable software products that propel startups to success. Our measure of triumph lies in the satisfaction our customers find in our creations. They are our inspiration, our prime movers. We endeavor to evoke smiles, ensuring each use is a moment of joy and a 'this is exactly what I need' experience. In a fast-paced world, our mission is to offer an oasis - a special, even magical, experience with every product we build.",
  mission_text:
    "Our mission is to spread joy globally by partnering with entrepreneurs in creating digital products that solve their customers' problems using innovative technologies.",
  vision_text:
    "We aim to create a powerful positive impact worldwide by building next-generation unicorn digital products. Our goal is to innovate and disrupt, foreseeing and shaping the future, transforming lives and industries.",
  core_values_title_one: "GROWTH:",
  core_values_text_one: "United evolution in our product journey.",
  core_values_title_two: "RESPECT:",
  core_values_text_two: "We show respect to all stakeholders and customers.",
  core_values_title_three: "EXCELLENCE:",
  core_values_text_three: "Full ownership, excellence in every aspect.",
  core_values_title_four: "ACCOUNTABILITY:",
  core_values_text_four: "Embracing process and outcomes, we're accountable.",
  core_values_title_five: "TEAMWORK:",
  core_values_text_five:
    "Designers, engineers, managers, clients - one team creating awesome products.",
  download_manifesto_text: "Check out full Manifesto",
  journey_title: "ScaleUp in Motion",
  journey_subtitle: "Our Journey",
  services_title: "Empowering Your Digital Presence",
  services_subtitle: "Services",
  services_text: "Discover Tailored Solutions with Our Comprehensive Services",
  services_title_one: "UI/UX Design",
  services_text_one:
    "Elevate user experiences with meticulously crafted interfaces that prioritize your customers' needs.",
  services_title_two: "Frontend Development",
  services_text_two:
    "Bring your web presence to life with modern and responsive interfaces designed for optimal user engagement.",
  services_title_three: "Mobile Development",
  services_text_three:
    "Unleash the power of cross-platform mobile applications, combining stunning design with swift performance.",
  services_title_four: "Backend Development",
  services_text_four:
    "Forge seamless, scalable, and high-performance backends, deployable across diverse cloud environments.",
  services_title_five: "Custom Development",
  services_text_five:
    "Embrace innovation with expertise in Artificial Intelligence, Blockchain, Embed, and beyond. Whatever your tech needs, we've got you covered.",
  tailored_programs_title: "Offerings",
  tailored_programs_subtitle: "Tailored Programs",
  tailored_programs_option_one_title: "Head Start",
  tailored_programs_option_one_text: "Let's build a roadmap to your startup.",
  tailored_programs_option_two_title: "Product Launchpad",
  tailored_programs_option_two_text:
    "Let's build from blueprint to brilliance, together.",
  tailored_programs_option_three_title: "Team Boost",
  tailored_programs_option_three_text:
    "Precision services for your specific demands.",
  head_start_text_one:
    "Ideal for - Founders with great idea, founders that want to validate idea with customers/investors, founders trying to pivot ",
  head_start_text_two:
    "Deliverables: Idea Visualization, Initial Branding, Tech Feasiblity and Implementation Plan, UI/UX Design, Shareable Clickable prototype, Strategy advising",
  head_start_text_three: "Product Stage: Idea stage / Seed stage",
  head_start_text_four: "Length - 3 weeks",
  product_launchpad_text_one:
    "Ideal for - Founders that are looking to build their product or to replace and onboard a new development team",
  product_launchpad_text_two:
    "Entire development team - frontend/mobile/backend development, UI/UX, project/product management, quality assurance",
  product_launchpad_text_three: "Pre-MVP stage, MVP stage, Post-MVP stage",
  team_boost_text_one:
    "Ideal for: founders/companies looking to expand their development team by onboarding a specific roles",
  team_boost_text_two:
    "Deliverables: Fill out dedicated roles and requests like 2 react developers, 2 machine learning engineers, etc.",
  team_boost_text_three: "Post-MVP stage, Enterprises",
  find_out_more_text: "Find Out More",
  ideal_for_text: "IDEAL FOR",
  deliverables_text: "DELIVERABLES",
  product_stage_text: "PRODUCT STAGE",
  length_text: "LENGTH",

  ambassador_title: "Ambassadors",
  ambassador_subtitle: "Become an Ambassador",
  ambassador_text:
    "Join us as Ambassadors, amplify our brand and visibility, be our super-connector and be part of the team that is building next generation products. All of that while enjoying the ScaleUp Ambassador’s benefits. Check out more and reach out to us!",
  contact_us_text: "Contact Us",
  stories_title: "Unveiling Our Case Studies",
  stories_subtitle: "ScaleUp Showroom",
  stories_text:
    "We're your global partners. Witness how we explore, outline, craft, and develop products from a basic concept.",
  stories_product_design: "Product Design",
  stories_development: "Development",
  stories_strategy: "Strategy",
  stories_quality_assurance: "Quality Assurance",
  stories_project_management: "Project Management",
  our_team_title: "Team",
  our_team_subtitle: "Meet Our Innovators",
  medium_articles_title: "Blog",
  medium_articles_subtitle: "Our Blog",
  medium_articles_text:
    "Dive into our articles on Medium for in-depth perspectives.",
  medium_article_one_title: "Input Form Validations with Yup in React Native",
  medium_article_one_text: "Mobile Development",
  medium_article_two_title: "Startup Series #1 -  Introduction",
  medium_article_two_text: "Startup",
  explore_more_on_medium: "Explore more on Medium",
  explore_more_on_medium_mobile: "Explore more",
  join_team_title: "Careers",
  join_team_subtitle: "Join Our Team ",
  join_team_text:
    "Ready to embark on a journey with ScaleUp? We're always on the lookout for talented individuals to join our dynamic team. If you're passionate about technology, innovation, and creating exceptional digital experiences, we want to hear from you!",
  application_title: "General Application",
  application_subtitle:
    "We believe in the power of diverse perspectives and unique talents, so whether you're a coding maestro, design virtuoso, or bring other valuable skills to the table, we welcome your application.",
  name_placeholder: "Full Name*",
  email_placeholder: "Email*",
  upload_resume: "+ Upload Resume*",
  linkedin_profile_placeholder: "Linkedin Profile",
  fullname_input_error: "Oops! Please add a name",
  email_input_error: "Hmm... that email doesn't look right",
  email_required_error: "Oops! Please add an email",
  resume_input_error: "Oops! Please add a resume",
  linkedin_input_error: "Hmm... that link doesn't look right",
  submit_text: "Submit",
  submit_success_message: "Successfully Sent",
  footer_title_one: "ScaleUp",
  footer_title_two: "Quick Links",
  footer_title_three: "Contact",
  footer_scaleup_text_one: "A forward-thinking tech partner.",
  footer_scaleup_text_two: "Join our community",
  footer_contact_text_one: "Radenka Abazovica 2A, Sarajevo",
  footer_contact_text_two: "Email: info@scaleup.ba",
  footer_copyright: "Ⓒ ScaleUp",

  adi_behlulovic_name: "Adi Behlulović",
  adi_behlulovic_position: "CEO",
  adi_behlulovic_text:
    "Seasoned IT industry veteran with 15 years of experience leading the development and launch of over 50 innovative startup products, including leading products through valuations exceeding 100 million EUR. Founder of ScaleUp company, dedicated to crafting the next generation of startup products.",
  adi_behlulovic_linkedin: "https://www.linkedin.com/in/adibehlulovic/",
  connect_with_adi: "👋 Connect with Adi",
  samra_behlulovic_name: "Samra Behlulović",
  samra_behlulovic_position: "Office Manager",
  samra_behlulovic_text:
    "Meet our office/HR manager, Samra! Apart from keeping our workspace running smoothly, she's a devoted mom to her energetic son. When she's not efficiently managing our office and HR affairs, you'll find her exploring new destinations with her family.",
  samra_behlulovic_linkedin:
    "https://www.linkedin.com/in/samra-behlulovi%C4%87-28b6b591/",
  connect_with_samra: "👋 Connect with Samra",
  sanjin_sehic_name: "Sanjin Šehić",
  sanjin_sehic_position: "Mobile Developer",
  sanjin_sehic_text:
    "Meet Sanjin, a Mobile Developer who loves diving into code. When he is not coding, he is into books, writing articles, watching sports, hitting the gym, testing his brain with quizzes, enjoying movies, and hanging out with friends. 📱✍️🎬🧠",
  connect_with_sanjin: "👋 Connect with Sanjin",
  sanjin_sehic_linkedin:
    "https://www.linkedin.com/in/sanjin-%C5%A1ehi%C4%87-5947a0172/",
  sanjin_sehic_medium: "https://medium.com/@sanjinsehic2",
  neira_durmic_name: "Neira Durmić",
  neira_durmic_position: "Frontend Developer",
  neira_durmic_text:
    "Meet Neira, a frontend developer who finds the process of turning lines of code into compelling visual experiences fascinating. This journey brings her joy, just like enjoying board games and hanging out with friends. 💻🎲🧩💬",
  connect_with_neira: "👋 Connect with Neira",
  neira_durmic_linkedin:
    "https://www.linkedin.com/in/neira-durmi%C4%87-492b99223?originalSubdomain=ba",
  ajdin_djakusic_name: "Ajdin Đakušić",
  ajdin_djakusic_position: "Backend Developer",
  ajdin_djakusic_text:
    "Meet Ajdin, a backend developer with a background in mechanical engineering. His passion for tech is a lifelong journey, and you will often find him immersed in building computers as a hobby during spare time. ⚙️👷🖥 💾",
  connect_with_ajdin: "👋 Connect with Ajdin",
  ajdin_djakusic_linkedin: "https://www.linkedin.com/in/ajdindjakusic/",
  ajdin_djakusic_medium: "https://medium.com/@ajdindjakusic",
  amina_mehic_name: "Amina Mehić",
  amina_mehic_position: "UX/UI Designer",
  amina_mehic_text:
    "Amina loves design, hitting the gym, and exploring mountain trails. When not crafting interfaces, you'll find her lost in a good book. For Amina, the mix of design, workouts, nature, and a captivating novel adds magic to daily moments.  🎨🏋️‍♀️📚✨",
  connect_with_amina: "👋 Connect with Amina",
  amina_mehic_linkedin: "https://www.linkedin.com/in/aminamehic/",
  amina_mehic_dribbble: "https://dribbble.com/amehic",
  amina_mehic_behance: "https://www.behance.net/aminamehic1",
  vehid_trtak_name: "Vehid Trtak",
  vehid_trtak_position: "Lead Frontend Developer",
  vehid_trtak_text:
    "Vehid is a seasoned software developer with more than a decade of experience. Beyond the world of coding, you'll find him immersed in the dynamic realms of sports and continuous learning. 👨🏻‍💻⚽📚📝",
  vehid_trtak_linkedin: "https://www.linkedin.com/in/vehid-trtak/",
  vehid_trtak_link: "https://www.vehidtrtak.com/",
  connect_with_vehid: "👋 Connect with Vehid",
  albin_didic_name: "Albin Didić",
  albin_didic_position: "Engineering Manager",
  albin_didic_text:
    "Dida, a passionate gamer, talented writer, and incredible individual, serves as our Engineering Manager. He leverages his extensive development and management experience to effectively lead our engineering efforts.",
  albin_didic_linkedin: "https://www.linkedin.com/in/adidic/",
  connect_with_albin: "👋 Connect with Albin",
  jasmin_fajkic_name: "Jasmin Fajkić",
  jasmin_fajkic_position: "Lead Mobile Developer",
  jasmin_fajkic_text: "",
  jasmin_fajkic_linkedin:
    "https://www.linkedin.com/in/jasmin-fajkic-674b81136/",
  connect_with_jasmin: "👋 Connect with Jasmin",
  amer_zildzic_name: "Amer Zildžić",
  amer_zildzic_position: "Lead Backend Developer",
  amer_zildzic_text:
    "Amer is an experienced backend engineer, writer and programming teacher. Beside building meaningful products, Amer likes to do organic gardening, DIY electronics, swimming, hiking and spending time in nature. 🌱📱🏊🏻‍♂️⛰️",
  amer_zildzic_linkedin:
    "https://www.linkedin.com/in/amer-zild%C5%BEi%C4%87-807857116/?originalSubdomain=ba",
  connect_with_amer: "👋 Connect with Amer",
  edin_hazihasic_name: "Edin Hazihasić",
  edin_hazihasic_position: "Motion Dessigner",
  edin_hazihasic_text: "",
  connect_with_edin: "👋 Connect with Edin",
  edin_hazihasic_linkedin: "",
  engineers_unleashed: "✌️ Engineers Unleashed",
  artistic_minds: "✌️ Meet Our Artistic Minds",
  meet_ceo: "✌️ Meet Our CEO",
  discover_operations: "✌️ Discover Operations",
  ai_toolkit: "AI Startup Toolkit",
};
